/**
* @author  XuHongli
* @date  2022/2/17 17:06
* @version 1.0
* @description
*/
<template>
  <div style="background: #ececec; padding: 30px;text-align: left;min-height: 100%">
    <div class="title-layout">
      <div class="page-title">
        基础信息
      </div>
      <a-button type="primary" @click="openCard(1,null)">
        添加银行卡
      </a-button>
    </div>
    <ul class="credit-rule">
      <li>1、绑定你的银行卡并授权使用它来支付账单，账单出账后系统会自动发起扣款。</li>
      <li>2、建议绑定多张卡，避免单张银行卡额度不足或其它异常，导致扣款失败影响店铺使用。</li>
      <li>3、若你设置了默认卡，系统优先使用默认卡发起扣费；若未设置默认卡，则系统优先对上次扣款成功的卡发起扣费。</li>
    </ul>
    <div v-if="list && list.length > 0">
      <a-card v-for="(item,index) in list" :key="index" :bordered="false" style="margin-top: 24px;">
        <ul class="credit-list">
          <li>
            <img height="30px" src="/images/visa-logo.png">
            <div class="cardNumber">
              卡号: <span>**** **** **** {{ item.cardNumber.toString().substring((item.cardNumber.toString().length - 4), item.cardNumber.toString().length) }}</span>
            </div>
            <div class="operation">
              <span @click="openCard(2,item.id)"> 更换</span>  |
              <span @click="delCard(item.id)"> 解绑</span> |
              <span @click="setFirst(item.id)"> 设为默认 </span>
            </div>
          </li>
        </ul>
      </a-card>

      <div class="card-pagination">
        <a-pagination
          v-model="page.current"
          :total="page.total"
          :page-size="10"
          @change="pageChange"
        />
      </div>
    </div>
    <div v-else class="no-data">
      暂无银行卡数据
    </div>

    <!-- 表单 -->
    <a-modal v-model="cardFormVisible" layout="Vertical" title="绑定银行卡" @ok="handleOk" @cancel="handleCancel">
      <a-form-model
        ref="ruleForm"
        style="width: 100%"
        :model="form"
        :rules="rules"
      >
        <a-form-model-item ref="name" label="请根据您持有银行卡的卡品牌，进行下一步绑定操作" prop="type">
          <!-- (1.国内 2.国外 ) -->
          <a-radio-group v-model="form.type" @change="typeChane">
            <div class="card-type">
              <a-radio value="2">
                绑定 Visa、MasterCard 和其它品牌的国际信用卡
              </a-radio>
            </div>
            <br>
            <div class="card-type">
              <a-radio value="1">
                绑定银联卡(支持银联信用卡和借记卡)
              </a-radio>
            </div>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item ref="cardNumber" label="卡号" prop="cardNumber">
          <a-input
            v-model="form.cardNumber"
            @blur="
              () => {
                $refs.cardNumber.onFieldBlur();
              }
            "
          />
        </a-form-model-item>

        <a-form-model-item v-if="outOrin" ref="name" label="持卡人姓名" prop="name">
          <a-input
            v-model="form.name"
            @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            "
          />
        </a-form-model-item>

        <a-form-model-item v-if="outOrin" ref="expirationTime" label="到期日期" prop="expirationTime">
          <a-date-picker v-model="form.expirationTime" format="YYYY-MM-DD HH:mm:ss" @change="onDataChange" />
        </a-form-model-item>

        <a-form-model-item v-if="outOrin" ref="safetyCode" label="安全码" prop="safetyCode">
          <a-input
            v-model="form.safetyCode"
            @blur="
              () => {
                $refs.safetyCode.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getBankCardList, addBankCard, delBankCard, getBankCard, editBankCard } from '@/api/masterCard'
export default {
  name: 'Credit',
  data() {
    return {
      list: [], // 银行卡列表

      // 分页对象
      page: {
        total: 0,
        current: 1
      },

      // 表单
      cardFormVisible: false,
      outOrin: true, // true国内/false国外
      form: {
        name: '',
        type: ''
      },
      rules: {
        type: [
          { required: true, message: '请选择银行卡类型', trigger: 'blur' }
        ],
        cardNumber: [
          { required: true, message: '请输入银行卡号', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入持卡人姓名', trigger: 'blur' }
        ],
        expirationTime: [
          { required: true, message: '请选择到期日期', trigger: 'change' }
        ],
        safetyCode: [
          { required: true, message: '请输入安全码', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.getCardList()
  },
  methods: {

    /**
     * @title 获取银行卡列表
     * @param page 分页对象
     */
    getCardList() {
      getBankCardList(this.page).then(res => {
        if (res.code === 0) {
          this.page.current = res.data.current
          this.page.total = res.data.total
          this.list = res.data.records
        }
      })
    },

    /**
     * @title 新增银行卡
     * @param form 银行卡对象
     */
    addCard() {
      addBankCard(this.form).then(res => {
        if (res.code === 0) {
          this.getCardList()
          this.cardFormVisible = false
        }
      })
    },

    /**
     * @title 编辑银行卡
     * @param form 银行卡对象
     */
    editCard() {
      editBankCard(this.form).then(res => {
        if (res.code === 0) {
          this.getCardList()
          this.cardFormVisible = false
        }
      })
    },

    /**
     * @title 删除银行卡
     * @param id 银行卡id
     */
    delCard(id) {
      delBankCard(id).then(res => {
        if (res.code === 0) this.getCardList()
      })
    },

    /**
     * @title 获取银行卡详情
     * @param id 银行卡id
     */
    viewCard(id) {
      getBankCard(id).then(res => {
        if (res.code === 0) {
          this.form = res.data
          this.cardFormVisible = true
        }
      })
    },

    setFirst() {

    },

    /**
     * @title 打开银行卡弹窗
     * @param type 1新增、2编辑
     * @param id 银行卡id
     */
    openCard(type, id) {
      if (type === 1) {
        this.form = {}
        this.cardFormVisible = true
      }
      if (type === 2) {
        this.viewCard(id)
      }
    },

    /**
     * @title 根据ID判断新增或者编辑
     */
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.form.id) {
            // 编辑
            this.editCard()
          } else {
            // 新增
            this.addCard()
          }
        }
      })
    },

    /** 关闭弹窗,移除验证效果 */
    handleCancel() {
      this.$refs.ruleForm.resetFields()
    },

    /**
     * @title 银行卡类型切换
     * @param 事件对象,获取单选目标值
     */
    typeChane(e) {
      const val = e.target.value

      // (1.国内true 2.国外false )
      if (val === '1') {
        this.outOrin = true
      } else {
        this.outOrin = false
      }
    },

    /**
     * @title 时间选择事件
     * @param dateString 选择的回调参数
     */
    onDataChange(date, dateString) {
      this.form.expirationTime = dateString
    },

    /**
     * @title 翻页
     * @param page 翻的页数
     */
    pageChange(page, pageSize) {
      this.page.current = page
      this.getCardList()
    }
  }
}
</script>

<style lang="scss" scoped>
.title-layout{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-title{
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 8px;
}
.credit-rule{
  background-color: #fff;
  padding: 20px 10px;
  border: 1px #0d0925 dashed;
  li{
    margin-top: 10px;
    font-size: 13px;
  }
  li:nth-of-type(1){
    margin-top: 0;
  }
}

.credit-list{
  li{
    height: 40px;
    background-color: #fff;
    margin-top: 10px;
    display: flex;
    width: inherit;
    align-items: center;
    .cardNumber{
      margin-left: 10px;
    }
  }
  li:nth-of-type(1){
    margin-top: 0;
  }
  .operation{
    margin-left: auto;
    color: #0364FF;
    cursor: pointer;
  }
}

// 暂无数据
.no-data{
  padding: 30px;
  font-size: 16px;
  text-align: center;
  color: #c9c6c6;
}

// 弹窗
::v-deep .ant-modal-header{
  background-color: #30354D;
  .ant-modal-title{
    color: #fff;
  }
}

::v-deep .ant-modal-close-x svg{
  color: #fff;
}

// 单选
::v-deep .ant-radio-group{
  width: 100%;
}

// 表单
.card-type{
  padding: 5px;
  border: 1px dotted gray;
}

.card-pagination{
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
</style>
