/**
* @author  dengqingbin
* @description 银行卡管理
*/

import request from '@/utils/request'

/**
 * 获取银行卡列表
 * @returns {*}
 */
export function getBankCardList(params) {
  return request({
    url: '/bankcard/page',
    method: 'get',
    params
  })
}

/**
 * 新增银行卡
 * @returns {*}
 */
export function addBankCard(data) {
  return request({
    url: '/bankcard/addBankCard',
    method: 'post',
    data
  })
}

/**
 * 删除银行卡
 * @returns {*}
 */
export function delBankCard(id) {
  return request({
    url: '/bankcard/delBankCard',
    method: 'post',
    data: {
      id
    }
  })
}

/**
 * 查询银行卡
 * @returns {*}
 */
export function getBankCard(id) {
  return request({
    url: '/bankcard/getById',
    method: 'get',
    params: {
      id
    }
  })
}

/**
 * 编辑银行卡
 * @returns {*}
 */
export function editBankCard(data) {
  return request({
    url: '/bankcard/updateBankCard',
    method: 'post',
    data
  })
}
